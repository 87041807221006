import autoComplete from "@tarekraafat/autocomplete.js";

window.addEventListener("load", () => {
  const autoCompleteJS = new autoComplete({
    selector: "#departement_autocomplete",
    placeHolder: "Ex. 69",
    data: {
      src: async (query) => {
        try {
          // Fetch Data from external Source
          const source = await fetch(`https://geo.api.gouv.fr/departements`);
          // Data is array of `Objects` | `Strings`
          const data = await source.json();
          return data;
        } catch (error) {
          return error;
        }
      },
      // Data 'Object' key to be searched
      keys: ["code", "nom"]
    },
    trigger: (query) => query.length >= 2,
    searchEngine: 'loose',
    resultsList: {
      tag: 'div',
      class: 'list-group'
    },
    resultItem: {
      tag: 'button',
      class: 'list-group-item',
      element: (item, data) => {
        item.setAttribute('type', 'button')
        item.innerHTML = data.value.nom + ' (' + data.value.code + ')'
      },
    }
  });

  const inputDepartement = document.querySelector('#etude_departement')
  autoCompleteJS.input.addEventListener("selection", function (event) {
    const feedback = event.detail;
    autoCompleteJS.input.blur();
    // Prepare User's Selected Value
    const selection = feedback.selection.value.nom;
    // Replace Input value with the selected value
    autoCompleteJS.input.value = selection;
    inputDepartement.value = feedback.selection.value.code
  });

  autoCompleteJS.input.addEventListener("close", function (event) {
    autoCompleteJS.input.value = ''
    inputDepartement.value = '';
  });
});