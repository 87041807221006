import { Modal } from 'bootstrap';
import IMask from 'imask';

import gtag from 'src/analytics'
import './autocomplete';

window.addEventListener("load", () => {
  const form = document.querySelector("#new-etude"),
    modal = new Modal(document.querySelector("#modal-confirmation"), {}),
    modalBody = document.querySelector("#modal-confirmation .modal-body");

  form.addEventListener("ajax:success", (event) => {
      const [_data, _status, xhr] = event.detail;
      modalBody.innerHTML = xhr.responseText;
      modal.show();

      gtag("event", "tarif", {
        'event_category': 'Tarificateur',
        'label': 'Le tarif a été demandé'
      })
  });

  form.addEventListener("ajax:error", () => {
    form.insertAdjacentHTML("beforeend", "<p>ERROR</p>");
  });

  const inputCoutTotalSubmit = document.querySelector('#etude_cout_total')
  const inputCoutTotalFormat = document.querySelector('#etude_cout_total_format')
  const maskCoutTotal = IMask(inputCoutTotalFormat, {
    mask: Number,
    // other options are optional with defaults below
    scale: 0,  // digits after point, 0 for integers
    signed: false,  // disallow negative
    thousandsSeparator: ' ',  // any single char
    normalizeZeros: true,
    radix: ',',
    mapToRadix: ['.']  // symbols to process as radix
  })

  const setFormatSubmit = () => {
    inputCoutTotalSubmit.value = maskCoutTotal.unmaskedValue
  }
  maskCoutTotal.on('accept', setFormatSubmit)
});